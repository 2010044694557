import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import SmallHero from '../../components/elements/small-hero/small-hero';
import SubIntro from '../../components/elements/sub-intro/sub-intro';

const VacanciesPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
    console.log(data)
  }, [])

  return (
    <Layout>
      <SEO
        keywords={[`Ely Beauty and Skin Clinc Vacancies`]}
        title="Vacancies"
      />
      <SmallHero 
    
        marginBottom
        loaded={loaded}
        title='Ely Beauty and Skin Clinc Vacancies'
      />
      <SubIntro 
        text={<div className="max-w-6xl mx-auto flex flex-wrap mb-20 md:mb-32">
        <div className="w-full md:w-1/2 mb-10">
          <div className="px-5 border-2 pb-5 pt-3 border-primary rounded-md">
            <h3 className="block mb-2 text-3xl">Hair Opportunities</h3>
            <p className=" text-xl mb-4">Rent a Chair - currently available Monday, Thursday and Friday.</p>
            <p className="text-sm">
                Our hair department is available for Rent a Chair on a 60/40 basis to include booking system, reception service, products, lounge and beautiful city centre setting! Please contact <a className="text-primary" href="mailto:elybeautyandskinclinic@gmail.com">elybeautyandskinclinic@gmail.com</a>
            </p>
          </div>
        </div>
      </div>}
      />
    </Layout>
  );
}

VacanciesPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    heroVacancies: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vacanciesbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const VacanciesComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <VacanciesPage props data={data} {...props} />
    )}
  />
)

VacanciesComponent.displayName = "VacanciesComponent"

export default VacanciesPage
